import React, { useEffect, useState } from "react";
//Components
import {
  getFromStorage,
  getRefreshToken,
  addToStorage,
  removeFromStorage,
} from "@utils/storage";
import {
  AUTH_PASS,
  URL_REFRESH_TOKEN,
  CLIENT_ID,
} from "@services/common/utils/settings";
//HOOK
import { useAuthorized } from "@customHooks/common/account/useAuthorized";
import { useAllInfo } from "@customHooks/common/account/useAllInfo";
import TwilioChat from "@src/modules/components/basic/common/others/TwilioChat";

const LoadingPage = ({ element }) => {
  //Variables necesarias porque lka página está fuera del "scope"
  const { setAuthorized, setAgent, axiosInstance } = useAuthorized(); //authorized,
  const { setUserInfo, setAccountInfo, getAllInfo } = useAllInfo(); //userInfo

  const removeSessionData = () => {
    removeFromStorage("token");
    removeFromStorage("stamp");
    removeFromStorage("agent");
    setUserInfo(null);
    setAccountInfo(null);
    setAuthorized(false);
    setIsLoaded(true);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = () => {
    const token = getFromStorage("token");
    const timeStamp = getFromStorage("stamp");
    const agent = getFromStorage("agent");
    if (!token || !timeStamp) {
      removeSessionData();
      return;
    }
    //Existen los dos
    if (Number(timeStamp) < new Date().getTime()) {
      axiosInstance({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${AUTH_PASS}`,
        },
        url: `${URL_REFRESH_TOKEN}`, //url: `${URL_BASE}${URL_REFRESH_TOKEN}`,
        params: {
          refresh_token: getRefreshToken(),
          grant_type: "refresh_token",
          client_id: CLIENT_ID,
        },
      })
        .then((tokenRefreshResponse) => {
          addToStorage("token", tokenRefreshResponse.data);
          addToStorage(
            "stamp",
            new Date().getTime() +
              Number(tokenRefreshResponse.data.expires_in) * 1000
          );
          setAuthorized(true); //Se hizo el refresh Token
          setIsLoaded(true);
          setAgent(agent);
          getAllInfo(() => {}, true);
        })
        .catch(() => {
          removeSessionData();
        });
      return;
    }

    setAuthorized(true);
    setIsLoaded(true);
    setAgent(agent);
    getAllInfo();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onLoad, []);

  return (
    <>
      {element}
      {<TwilioChat />}
    </>
  );
};
export default LoadingPage;
