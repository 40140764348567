import * as Sentry from "@sentry/browser";

//El metodo antesrior de process call devolvia un objeto con este formato
export const convertResponseForEvaluation = (response) => {
  return {
    ok: response.ok,
    status: response.status,
    data: { status: response.status, data: response.data },
  };
};

export default function responseDataEvaluation(response) {
  if (!response) {
    return { status: "ko" };
  }
  const responseData = response.data;

  let data = {
    status: "ok",
    useAlert: false,
    useRedirect: false,
    alertRedirect: false,
    authChange: response.authChange,
    route: null,
    data: responseData,
    alertData: null,
  };
  if (responseData.data === null || responseData.data === undefined) {
    //Ocurre cuando el token no existe y se hacen peticionesa back que lo requieren
    data.status = "ko";
    data.useRedirect = true;
    data.route = "HOME";
  } else {
    if (responseData.data.body) {
      data.code = responseData.data.body.code;
      data.message = responseData.data.body.description;
    } else {
      data.code = responseData.data.code;
      data.message = responseData.data.description;
    }
    if (data?.code !== 0 && data?.code !== undefined && data?.code !== null) {
      data.multiTitle = `error.${data.code}.title`;
      data.multiText = `error.${data.code}.text`;
    }
    //Respuestas 200 o 412
    if (
      response.status === 200 ||
      response.status === 412 ||
      response.status === 406 ||
      response.status === 417
    ) {
      //417 error de tokenización de tarjetas
      if (data.code !== null && data.code !== undefined) {
        //undefined
        switch (data.code) {
          case -1: //Error de tokenización de tarjeta
            //error como en
            data.status = "ko";
            data.formId = "number";
            break;
          case 0: //Exito
            data.status = "ok";
            break;
          case 6:
            data.status = "ko";
            data.formId = "month";
            //addCard
            break;
          case 7:
            data.status = "ko";
            data.formId = "year";
            //addCard
            break;
          case 8:
            data.status = "ko";
            data.formId = "number";
            //addCard
            break;
          case 9:
            data.status = "ko";
            data.formId = "cvv";
            //addCard
            break;
          case 201: //Exito
            data.status = "ok";
            break;
          case 1105:
            data.status = "ok";
            //data.formId = "pin"
            break;
          case 1106:
            data.status = "ok";
            //data.formId = "pin"
            break;
          case 1108: // “Id de dispositivo”
            data.status = "ko";
            data.useAlert = true;
            break;
          case 1110: //PIN NO VÁLIDO
            data.status = "ko";
            data.formId = "pin";
            break;
          case 1111: //PIN AUTH
            data.status = "ko";
            data.formId = "pin";
            break;
          case 1121: //PIN AUTH
            data.status = "ko";
            data.formId = "phone";
            break;
          case 1122: //EMAIL AUTH
            data.status = "ko";
            data.formId = "email";
            break;
          case 1125: //EMAIL AUTH
            data.status = "ko";
            data.formId = "email";
            break;
          case 1126: //PIN AUTH
            data.status = "ok";
            //data.route = "ACCESS_EMAIL_VERIFICATION"
            //setConfirmMessage(result.body.description)
            break;
          case 1130: //TOKEN VALIDATION
            data.status = "ok";
            //data.route = "ACCESS_EMAIL_VERIFICATION"
            //setConfirmMessage(result.body.description)
            break;
          case 1146: //PIN AUTH
            data.status = "ok";
            data.useAlert = true;
            data.alertNavigate = "HOME";
            //setConfirmMessage(result.body.description)
            break;
          case 1150: //PIN NO VÁLIDO EN UPDATE DE NÚMERO
            data.status = "ko";
            data.formId = "pin";
            break;
          case 1159: //“Se requiere correo”
            data.status = "ok";
            break;
          case 100000001: //“Se requiere correo”
            data.status = "ok";
            break;
          case 1163: // “Captcha inválido”
            data.status = "ko";
            data.useAlert = true;
            break;
          case 1164: // “El captcha es requerido”
            data.status = "ko";
            data.useAlert = true;
            break;
          case 2001: // “description”: “datos incorrectos para crear contacto”
            data.status = "ko";
            data.formId = "errorValues";
            data.errorData = responseData.data.data.errorValues;
            break;
          case 2150: // “description”: "El dato phoneNumber no es válido”
            data.status = "ko";
            data.useAlert = true;
            break;
          case 4031: // NO se pudo agregar la TARJETA comprueba los datos
            data.status = "ko";
            data.useAlert = true;
            break;
          case 4200: // invalidpaymentmethod - validar tarjeta
            data.status = "ko";
            data.useAlert = true;
            break;
          case 4201: // invalidcode - validar tarjeta
            data.status = "ko";
            data.formId = "code";
            break;
          case 4202: // notfound - validar tarjeta
            data.status = "ko";
            data.useAlert = true;
            break;
          case 4203: // wrongpin - validar tarjeta
            data.status = "ko";
            data.formId = "code";
            break;
          case 4204: // maxtries - validar tarjeta
            data.status = "ko";
            data.useAlert = true;
            break;
          case 4205: // wrongcents - validar tarjeta
            data.status = "ko";
            data.formId = "cents";
            break;
          case 4271: // wrong card number - remittances card
            data.status = "ko";
            data.formId = "number";
            break;
          case 7014:
            data.status = "ko";
            data.useAlert = true;
            break;
          case 7029:
            data.status = "ko";
            data.useAlert = true;
            break;
          case 7031:
            data.status = "ko";
            data.useAlert = true;
            break;
          case 7059:
            data.status = "ko";
            data.useAlert = true;
            data.alertNavigate = "ROUTE_CART";
            break;
          case 7224:
            data.status = "ok";
            break;
          case 8026:
            data.status = "ko";
            data.useAlert = true;
            break;
          default:
            data.status = "ko";
            data.useAlert = true;
            break;
        }
      } else {
        if (response.status === 200) {
          data.status = "ok";
        } else {
          data.status = "ko";
          data.useAlert = true;
          data.route = "HOME"; //Por defecto
        }
      }
    } else {
      //Respuestas diferentes a 200 o 412
      data.status = "ko";
      switch (response.status) {
        case 400:
          switch (data.data.data.code ? data.data.data.code : null) {
            case 1139: //CREDENCIALES INCORRECTAS
              data.formId = "password";
              break;
            case 1146: //CREDENCIALES INCORRECTAS
              data.status = "ko";
              data.useAlert = true;
              data.alertNavigate = "ACCESS";
              break;
            default:
              //data.useAlert = true
              //data.alertNavigate  = "HOME"
              data.useRedirect = true;
              data.route = "ACCESS";
          }
          break;
        case 500:
          data.useAlert = true;
          data.alertNavigate = "HOME";
          data.multiTitle = `error.500.title`;
          data.multiText = `error.500.text`;
          break;
        default:
          data.useAlert = true;
          data.alertNavigate = "HOME";
          data.useRedirect = true;
          break;
      }
      //en caso de error de sistema sin alerta, se redirige a la página de error
      if (!data.useAlert && !data.formId) {
        data.status = "ko";
        data.route = "HOME";
        //data.route = "ROUTE_ERROR_SERVER"
        data.useRedirect = true;
      }
    }
  }
  if (data.useAlert) {
    data.alertData = getAlertData(data);
  }

  // Sentry
  if (data.status === "ko") {
    Sentry.withScope(function (scope) {
      scope.setLevel("error");
      Sentry.setTag("custom_status", response.status);
      Sentry.setTag("custom_code", data.code);
      Sentry.setTag("custom_message", data.message);
      // Truncar el valor de response para que no de error en Sentry, ya que no admite valores muy largos
      const truncatedResponse = JSON.stringify(response).substring(0, 1000); // Limitar a 1000 caracteres
      Sentry.setTag("custom_response", truncatedResponse);
      try {
        Sentry.captureException(
          new Error(
            "Custom Error " + (response.status ? response.status : data.code)
          )
        );
      } catch (err) {
        throw new Error(
          "Custom Error " + (response.status ? response.status : data.code)
        );
      }
    });
  }
  // END Sentry
  return data;
}

function getAlertData(data) {
  const alertData = {
    type: "accept",
    data: {
      icon: "warning",
      code: data.code,
      serviceText: data.message || null,
      multiTitle: data.multiTitle,
      multiText: data.multiText,
      navigate: data.alertNavigate || null,
      redirect: data.alertRedirect || null,
    },
  };
  return alertData;
}
