module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Envíos a Cuba | Envío de paquetes | Cuballama Envíos","short_name":"Cuballama Envíos","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d6434af99a27cbb14d1ac1aad456f302"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","en-CA","en-ES","es-US","es-ES","es-CA"],"defaultLanguage":"es-US","redirect":false,"siteUrl":"https://www.cuballama.com","trailingSlash":"ignore","i18nextOptions":{"nonExplicitSupportedLngs":true,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/wp/:lang/(.*)","getLanguageFromPath":true},{"matchPath":"/api/:lang/(.*)","getLanguageFromPath":true},{"matchPath":"/deeplink/(.*)","languages":["es-US"]},{"matchPath":"/unique/(.*)","languages":["es-US"]},{"matchPath":"/404","languages":["es-US"]},{"matchPath":"/404.html","languages":["es-US"]},{"matchPath":"/dev-404-page","languages":["es-US"]}]},
    },{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp-cms.envios.cuballama.net/graphql","debug":{"preview":true,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
