import {
  URL_SHIPPING_BASE,
  URL_GET_API_RECEIVERS_REMOVE,
} from "@services/common/utils/settings";
import responseEvaluation from "@services/common/utils/serviceResponseEvaluation";
import { getFromStorage } from '@src/utils/storage';

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const removeReceiver = async (id, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_RECEIVERS_REMOVE}?receiverAddressId=${id}&agent=${!!getFromStorage("agent")}`;
  const bodyData = {};
  //Se procesa la petición
  return axiosInstance
    .post(apiURL, bodyData)
    .then((response) => {
      const evaluation = responseEvaluation(response);
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default removeReceiver;
