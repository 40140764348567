import {
  URL_SHIPPING_BASE,
  URL_API_ADD_TO_CART,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";
import { getFromStorage } from '@src/utils/storage';

const formatApiResponse = (apiResponse) => {
  const data = {
    total: apiResponse.data.data.checkoutData.products.lenght,
    data: apiResponse.data.data.checkoutData,
  };
  return data;
};

const addCartItem = async ({ productData, axiosInstance }) => {
  if (!productData) return null;
  const apiURL = `${URL_SHIPPING_BASE}${URL_API_ADD_TO_CART}`;
  const bodyData = {
    ...productData,
    agent: !!getFromStorage("agent")
  }
  return axiosInstance
    .post(apiURL, bodyData)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
export default addCartItem;
