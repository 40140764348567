import {
  URL_SHIPPING_BASE,
  URL_GET_API_SENDER_UPDATE,
} from "@services/common/utils/settings";
import responseEvaluation, {
  convertResponseForEvaluation,
} from "@services/common/utils/serviceResponseEvaluation";
import { getFromStorage } from '@src/utils/storage';

//Formateo específico de datos de cada servicio
const formatApiResponse = (apiResponse) => {
  return apiResponse.data.data.data;
};

const updateSender = async (data, axiosInstance) => {
  const apiURL = `${URL_SHIPPING_BASE}${URL_GET_API_SENDER_UPDATE}`;
  const bodyData = {
    ...data,
    agent: !!getFromStorage("agent")
  }
  return axiosInstance
    .post(apiURL, bodyData)
    .then((response) => {
      const evaluation = responseEvaluation(
        convertResponseForEvaluation(response)
      );
      if (evaluation.status === "ok") {
        evaluation.formattedData = formatApiResponse(response);
      }
      return evaluation;
    })
    .catch((error) => {
      console.error(error);
      return error?.response?.data ?? null;
    });
};
export default updateSender;
